<template>
  <div class="my_suggest">
    <div class="warpper">
      <h2 class="title">基本信息设置</h2>
      <div class="form-section">
        <el-form ref="form" :model="form" label-width="90px" :rules="rules">
          <el-form-item label="姓名：" prop="name">
            <el-input v-model="form.name" maxlength="30" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="头像：" prop="avatar">
            <UploadCover @uploadCover="handleCoverChange" :xcover="form.avatar"></UploadCover>
          </el-form-item>
          <el-form-item label="学号：" prop="studyNumber" v-if="studentShow">
            <el-input v-model.number="form.studyNumber"></el-input>
          </el-form-item>
          <el-form-item label="工号：" v-if="!studentShow">
            <el-input v-model="form.jobNumber"></el-input>
          </el-form-item>
          <el-form-item label="身份证号：" prop="idCard" v-if="studentShow">
            <el-input v-model="form.idCard" :maxlength="18"></el-input>
          </el-form-item>
          <el-form-item label="身份：" prop="role" v-if="!studentShow">
            <p>教师</p>
          </el-form-item>
          <el-form-item label="性别：" prop="sex">
            <el-radio-group v-model="form.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="0">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="邮箱：" prop="email" v-if="!studentShow">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="地区：" prop="area">
            <p>{{ form.area || '无'}}</p>
          </el-form-item>
          <el-form-item label="学校：" prop="school">
            <p>{{ form.schoolName || '无'}}</p>
          </el-form-item>
          <el-form-item label="年级：" prop="grade">
            <p>{{ form.gradeName || '无'}}</p>
          </el-form-item>
          <el-form-item label="班级：" prop="class">
            <p>{{ form.clazzName || '无'}}</p>
          </el-form-item>
          <el-form-item label="学科：" v-if="!studentShow">
            <el-select v-model="form.subjectId" placeholder="请选择学科">
              <el-option v-for="(subject,index) in subjectList" :key="index" :label="subject.name" :value="subject.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职称：" v-if="!studentShow">
            <el-select v-model="form.titleId" placeholder="请选择职称">
              <el-option v-for="(title,index) in titleList" :key="index" :label="title.name" :value="title.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任教年龄：" v-if="!studentShow">
            <el-input v-model="form.experience"></el-input>
          </el-form-item>
          <el-form-item label="个人简介：" v-if="!studentShow">
            <el-input type="textarea" v-model="form.introduce" rows="6" resize="none" maxlength="250" show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item class="el-form-item-btns">
            <el-button @click="resetForm('form')">取消</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    inject: ['reload'],
    data() {
      var validateJobNumber = (rule, value, callback) => {
        if (value === "") {
          return callback(new Error("请输入工号"));
        }
        setTimeout(() => {
          if (!Number.isInteger(value)) {
            callback(new Error("请输入数字值")); //TODO:密码要求
          } else {
            callback();
          }
        }, 1000);
      };
      var validateIDcard = (rule, value, callback) => {
        // console.log(value);
        let reg =
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        // console.log(reg.test(value));
        if (value === "") return callback();
        if (!reg.test(value)) {
          return callback(new Error("请输入正确的身份证号码"));
        } else {
          return callback();
        }
      };
      var validatePhone = (rule, value, callback) => {
        let reg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (value === "") {
          callback(new Error("请输入手机号"));
        } else if (!reg.test(value)) {
          callback(new Error("请输入正确的手机号码!"));
        } else {
          callback();
        }
      };
      var validateEmail = (rule, value, callback) => {
        let reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if (value === "") {
          callback(new Error("请输入邮箱地址"));
        } else if (!reg.test(value)) {
          callback(new Error("请输入格式正确的邮箱地址!"));
        } else {
          callback();
        }
      };
      return {
        form: {
          // name: "",
          // jobNumber: 0,
          // idCard: "",
          // sex: "",
          // phone: 0,
          // email: "",
          // area: "",
          // schoolId: "",
          // gradeId: "",
          // classId: "",
          // subjectId: "",
          // titleId: "",
          // experience: "",
          // avatar:""
        },
        rules: {
          name: [{
            require: true,
            message: '请输入姓名',
            trigger: 'blur'
          }],
          phone: [{
            validator: validatePhone,
            trigger: "blur"
          }],
          idCard: [{
            validator: validateIDcard,
            trigger: "blur"
          }],
        },
        role: '',
        studentShow: false,
        titleList: [],
        subjectList: [],
      };
    },
    watch: {
      beforeRouteUpdate(to, from, next) {
        //在当前路由改变，但是该组件被复用时调用
        //对于一个带有动态参数的路径 /good/:id，在 /good/1 和 /good/2 之间跳转的时候，
        // 由于会渲染同样的good组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
        // 可以访问组件实例 `this`
        // console.log(this, "beforeRouteUpdate"); //当前组件实例
        // console.log(to, "组件独享守卫beforeRouteUpdate第一个参数");
        // console.log(from, "组件独享守beforeRouteUpdate卫第二个参数");
        // console.log(next, "组件独享守beforeRouteUpdate卫第三个参数");
        next();
      }
    },
    computed: {},
    methods: {
      handleCoverChange(data) {
        // console.log(data)
        this.form.avatar = data.md5
      },

      // 获取职称列表
      async getTitleList() {
        let resData = await this.$Api.My.getTitle();
        // console.log(resData)
        this.titleList = resData.data
      },
      // 获取学科列表
      async getSubjectList() {
        let resData = await this.$Api.My.getSubject();
        // console.log(resData)
        this.subjectList = resData.data
      },
      onSubmit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.commitForm();
          }
        })
      },
      async commitForm() {
        // let params = {
        //   name: String(this.form.name),
        //   avatar:this.form.cover,
        //   jobNumber: String(this.form.jobNumber),
        //   idCard: String(this.form.idCard),
        //   phone: this.form.phone,
        //   sex: this.form.sex,
        //   email: String(this.form.email),
        //   experience:this.form.experience,
        //   subjectId:this.form.subjectId,
        //   titleId:this.form.subjectId,
        //   introduce:this.form.introduce
        // };
        // console.log(this.form);
        let resData = await this.$Api.My.getfixinfo(this.form);
        // console.log(resData);
        if (resData.data.code === 200 && resData.data.msg === '请求成功') {
          this.$notify({
            title: "成功",
            message: "个人信息修改成功",
            type: "success"
          });
          this.userinfo()
          // this.$router.go(0)
          setTimeout(() => {
            this.reload();
            window.scroll(0, 0)
          }, 100)
        } else {
          this.$notify.error({
            title: "错误",
            message: resData.data.msg
          });
        }
        // this.init();
      },
      async userinfo() {
        let resData = await this.$Api.Home.userInfo();
        // console.log(resData)
        // sessionStorage.userinfo = JSON.stringify(resData.data);
        this.$store.commit('getUserInfo', resData.data);
        this.form = resData.data
      },
      async init() {
        this.role = this.$store.state.user.role;
        this.studentShow = this.role === "student";
        // let resData = await this.$Api.Home.userInfo();
        // console.log(resData);
        // this.form = resData.data;
        this.userinfo();
        this.getTitleList();
        this.getSubjectList();
      },
      resetForm(formName) {
        // this.init();
        this.$router.go(-1); //返回上一层
        // this.$refs['form'].resetFields();
      }
    },
    mounted() {
      this.init();
    },
    activated() {
      this.init();
    }
  };
</script>

<style lang="less" scoped>
  .my_suggest {
    border-radius: 10px;

    background: #ffffff;

    .fengmian_upload {
      text-align: center;
      border: 1px dashed #888888;
      padding: 15px 20px;
      border-radius: 10px;
      cursor: pointer;

      .fengmian_image {
        width: 50px;
        height: 40px;
      }

      .fengmian_title {
        line-height: 30px;
        height: 30px;
        text-align: center;
        font-size: 12px;
        color: #888888;
        font-weight: 400;
      }
    }

    .warpper {
      padding-right: 9px;
    }

    .title {
      border-bottom: 1px solid #f9f9f9;
      padding-left: 35px;
      line-height: 69px;

      font-size: 22px;
    }

    .form-section {
      padding: 35px 50px 77px 49px;

      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
      }

      .avatar {
        width: 120px;
        height: 120px;
        display: block;
      }

      .el-input {
        max-width: 300px;
      }

      .el-textarea__inner {
        max-width: 480px;
      }

      .el-form-item-btns {
        margin-top: 40px;

        .el-button {
          font-size: 16px;
          padding: 0px 63px;

          &:first-child {
            color: #508EF9;
            border: 1px solid #508EF9;
          }

          &:last-child {
            margin-left: 80px;
            background-color: #508EF9;

            &:hover {
              background-color: rgba(80, 142, 249, 0.8);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1250px) {
    .my_suggest {
      max-width: 660px;
      width: 660px;

      .form-section .el-form-item-btns .el-button:last-child {
        margin-left: 40px;
      }
    }
  }
</style>